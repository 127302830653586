import '@fontsource-variable/figtree';
import '@fontsource-variable/hanken-grotesk';
import './styles/index.pcss';
import './setupSentry';
import.meta.glob('./assets/**/*', { eager: true, import: 'default' });

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';

import { counter } from './modules';

Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(
	Tooltip.defaultProps({
		theme: 'secondary',
		arrow: roundArrow,
	}),
);

Alpine.data('counter', counter);
Alpine.start();
